import Swiper, {Lazy, Navigation, Pagination} from 'swiper';

import 'swiper/swiper-bundle.css';
import AdFormService from '../services/AdFormService';
import PageData from '../PageData';
import {Device} from '../types/Device';
import {Brand} from '../types/Brand';
import {Section} from '../services/Section';
import {FormActions} from "../forms/FormActions";
import Player = YT.Player;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const youtube = require('youtube-iframe-player');

export default class ImageGallery {
  private readonly mySwiper: Swiper;

  private fullPageGallerySwiper!: Swiper;

  private youtubePlayer!: Player;

  private readonly device: string;

  private readonly swiperPaginationPhotosCounter: HTMLElement | null;

  private readonly currentPhoto: HTMLElement | null;

  private readonly existCurrentPhoto: boolean;

  private readonly floorPlanButton!: HTMLElement | null;

  private readonly virtualTourButton!: HTMLElement | null;

  private readonly videoButton!: HTMLElement | null;

  private readonly imagesButton!: HTMLElement | null;

  private readonly photoCameraButton!: HTMLElement | null;

  private readonly floorPlansUrls: Array<string> | undefined;

  private readonly virtualTourUrls: Array<string> | undefined;

  private swiperSlidesImages!: Array<HTMLElement>;

  private readonly pageData : PageData;

  private readonly goToFormButton!: HTMLElement | null;

  private lazyLoading: boolean;

  private constructor(pageData: PageData, swiperSelector: string, lazyLoading: boolean) {
    this.pageData = pageData;
    this.device = pageData.device;
    this.swiperPaginationPhotosCounter = document.getElementById('swiper-pagination-photos-counter');
    this.lazyLoading = lazyLoading;
    this.mySwiper = this.createImageGallery(swiperSelector, '.swiper-button-prev', '.swiper-button-next');
    if (this.swiperPaginationPhotosCounter) this.swiperPaginationPhotosCounter.classList.remove('swiper-pagination-fraction');
    this.currentPhoto = document.getElementById('middle-wrapper-current-photo');
    this.existCurrentPhoto = !!this.currentPhoto;
    this.floorPlanButton = document.getElementById('floor-plan');
    this.virtualTourButton = document.getElementById('virtual-tour');
    this.videoButton = document.getElementById('video');
    this.imagesButton = document.getElementById('images');
    this.photoCameraButton = document.getElementsByClassName('middle-wrapper__icons-gallery--photos-counter-icon')[0] as HTMLElement;
    this.floorPlansUrls = pageData.floorPlans;
    this.virtualTourUrls = pageData.virtualTours;
    if ((pageData.brand === Brand.NESTORIA || pageData.device === Device.DESKTOP)) {
      this.goToFormButton = document.getElementById('go-to-form-button');
    }

    const prevButton = document.getElementById('swiper-button-prev');
    const nextButton = document.getElementById('swiper-button-next');

    if (prevButton) {
      prevButton.addEventListener('click', this.updateExternalPhotosCounter);
    }
    if (nextButton) {
      nextButton.addEventListener('click', this.updateExternalPhotosCounter);
    }

    document.addEventListener('loadFullPageSwiper', () => {
      if (this.fullPageGallerySwiper == undefined) {
        this.fullPageGallerySwiper = this.createImageGallery(
          '.full-page-gallery-swiper-container',
          '.full-page-swiper-button-prev',
          '.full-page-swiper-button-next',
        );
      }
    });

    document.addEventListener('sliderSlideSelected', (event) => {
      if (this.fullPageGallerySwiper != undefined) {
        this.fullPageGallerySwiper.slideTo((event as CustomEvent).detail);
      }
    });

    document.addEventListener('syncBothSwipersSlides', (event) => {
      this.fullPageGallerySwiper.slideTo((event as CustomEvent).detail);
    });

    document.addEventListener('syncMainSwipersSlides', (event) => {
      this.mySwiper.slideTo((event as CustomEvent).detail);
    });

    const swiperButtonsVideo = document.getElementsByClassName('swiper-button-video');
    if (swiperButtonsVideo.length > 0) {
      for (const swiperButtonVideo of swiperButtonsVideo as any) {
        swiperButtonVideo.addEventListener('click', this.goToYoutubeVideoSlide);
      }
    }

    if (this.floorPlanButton) this.floorPlanButton.addEventListener('click', this.goToFloorPlanSlide);
    if (this.virtualTourButton) this.virtualTourButton.addEventListener('click', this.goToVirtualTourPlanSlide);
    if (this.imagesButton) this.imagesButton.addEventListener('click', this.goToFirstImage);

    if (this.goToFormButton) this.goToFormButton.addEventListener('click', () => {new FormActions(pageData).highlightForm(Section.SECTION_ADPAGE_IMAGE_CAROUSEL_CONTACT)});

    this.swiperSlidesImages = Array.from(document.querySelectorAll(
      'img[data-element=\'main-swiper-slide\']',
    ));
    this.identifyAndMarkFloorPlansSlides();
  }

  static init = (pageData: PageData, swiperSelector: string = '.gallery-swiper-container', lazyLoading: boolean = false): ImageGallery => {
    return new ImageGallery(pageData, swiperSelector, lazyLoading);
  };

  playerReady = () => {
    document.getElementsByClassName('swiper-youtube-button-container')[0].addEventListener('click', this.playVideo);
  };

  playVideo = () => {
    this.youtubePlayer.playVideo();
  };

  getVideoID = (): string => document.getElementsByTagName('meta').namedItem('videoId')!.content;

  onPlayerStateChange = (swiper: Swiper) => {
    if (this.youtubePlayer.getPlayerState() == YT.PlayerState.PLAYING) {
      document.getElementsByClassName('swiper-pagination-container')[0].classList.add('swiper-pagination-container-hidden');
      document.getElementsByClassName('swiper-youtube-button-container')[0]!.classList.add('swiper-youtube-button-container-hidden');
    }

    if (this.youtubePlayer.getPlayerState() == YT.PlayerState.PAUSED) {
      document.getElementsByClassName('swiper-pagination-container')[0].classList.remove('swiper-pagination-container-hidden');

      if (swiper.activeIndex == (this.getVideoIndexMeta())) {
        document.getElementsByClassName('swiper-youtube-button-container')[0]!.classList.remove('swiper-youtube-button-container-hidden');
      }
    }

    if (this.youtubePlayer.getPlayerState() == YT.PlayerState.ENDED) {
      document.getElementsByClassName('swiper-pagination-container')[0].classList.remove('swiper-pagination-container-hidden');
      document.getElementsByClassName('swiper-youtube-button-container')[0]!.classList.add('swiper-youtube-button-container-hidden');
    }

    if (this.youtubePlayer.getPlayerState() == YT.PlayerState.UNSTARTED) {
      document.getElementsByClassName('swiper-pagination-container')[0].classList.remove('swiper-pagination-container-hidden');
      document.getElementsByClassName('swiper-youtube-button-container')[0]!.classList.add('swiper-youtube-button-container-hidden');
    }
  };

  private createYoutubePlayer = () => {
    // DOCUMENTATION
    // https://developers.google.com/youtube/iframe_api_reference?hl=es-419
    // https://developers.google.com/youtube/player_parameters?hl=es-419

    youtube.init(() => {
      this.youtubePlayer = youtube.createPlayer('youtubePlayer', {
        playerVars: {
          autoplay: 0,
          controls: 1,
          iv_load_policy: 3,
          modestbranding: 0,
          rel: 0,
          showinfo: 0,
        },
        videoId: this.getVideoID(),
        events: {
          onReady: this.playerReady,
          onStateChange: () => {
            this.onPlayerStateChange(this.mySwiper);
            this.onPlayerStateChange(this.fullPageGallerySwiper);
          },
        },
      });
    });
  };

  private goToYoutubeVideoSlide = () => {
    const youtubeVideoSlideIndex = (document.querySelectorAll('div[data-videoslide=\'true\']')[0] as HTMLElement).dataset.slide;
    this.mySwiper.slideTo(parseInt(youtubeVideoSlideIndex as string), 0);
  };

  private goToFloorPlanSlide = () => {
    const floorPlanSlideIndex = (document.querySelectorAll('img[data-plan=\'true\']')[0] as HTMLElement).dataset.slide;
    this.mySwiper.slideTo(parseInt(floorPlanSlideIndex as string), 0);
    this.floorPlanButton?.classList.add('selected');
  };

  private goToVirtualTourPlanSlide = () => {
    const virtualTourSlideIndex = (document.querySelectorAll('iframe[data-virtualtour=\'true\']')[0] as HTMLElement).dataset.slide;
    this.mySwiper.slideTo(parseInt(virtualTourSlideIndex as string), 0);
    this.virtualTourButton?.classList.add('selected');
  };

  private goToFirstImage = () => {
    const currentSlideIndex = this.mySwiper.activeIndex;
    if (currentSlideIndex === 0) return;
    this.mySwiper.slideTo(0, 0);
    this.imagesButton?.classList.add('selected');
  };

  private toggleFloorPlansButtonState = (currentSlideIndex: number) => {
    const slide = document.querySelector(`img[data-slide='${currentSlideIndex}']`);
    if (!slide || !(slide as HTMLElement).dataset.plan) {
      this.floorPlanButton?.classList.remove('selected');
    } else {
      this.floorPlanButton?.classList.add('selected');
    }
  };

  private toggleVirtualTourButtonState = (currentSlideIndex: number) => {
    const slide = document.querySelector(`[data-slide='${currentSlideIndex}']`);
    if (!slide || !(slide as HTMLElement).dataset.virtualtour) {
      this.virtualTourButton?.classList.remove('selected');
    } else {
      this.virtualTourButton?.classList.add('selected');
    }
  };

  private togglePhotoCameraButtonState = (currentSlideIndex: number) => {
    if (this.isFloorPlanAvailable() || this.isVideoAvailable()) {
      const slide = document.querySelector(`img[data-slide='${currentSlideIndex}']`);
      if ((slide && (slide as HTMLElement).dataset.plan) || (this.getVideoIndexMeta() === (currentSlideIndex + 1))) {
        this.photoCameraButton?.classList.remove('selected');
      } else {
        this.photoCameraButton?.classList.add('selected');
      }
    }
  };

  private getVideoIndexMeta = (): number => (document.getElementsByTagName('meta').namedItem('videoIndex') != null
    ? parseInt(document.getElementsByTagName('meta').namedItem('videoIndex')!.content)
    : 0);

  private updateExternalPhotosCounter = () => {
    if (this.existCurrentPhoto) {
      this.currentPhoto!.textContent = `${this.mySwiper.realIndex + 1}`;
    }
  };

  private createImageGallery = (swiperSelector: string, prevButton: string, nextButton: string): Swiper => {
    Swiper.use([Navigation, Pagination, Lazy]);
    const baseConfig = {
      direction: 'horizontal',
      loop: false,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },

      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
    }
    let lazyConfig = {}
    if (this.lazyLoading) {
      lazyConfig = {
        preloadImages: false,
        lazy: {
          enabled: true,
          loadPrevNext: false,
          checkInView: true,
          loadOnTransitionStart: true,
        },
      }
    }

    // @ts-ignore
      const mySwiper = new Swiper(swiperSelector, {...baseConfig, ...lazyConfig} );

    mySwiper.on('slideNextTransitionStart', () => {
      const event = new Event('scrollSliderToTheRight');
      document.dispatchEvent(event);
      this.emmitSlideChangingEvent(mySwiper.activeIndex);
    });

    mySwiper.on('slidePrevTransitionStart', () => {
      const event = new Event('scrollSliderToTheLeft');
      document.dispatchEvent(event);
      this.emmitSlideChangingEvent(mySwiper.activeIndex);
    });

    mySwiper.on('slideChangeTransitionEnd', (event) => {
      this.updateExternalPhotosCounter();
      this.togglePhotoCameraButtonState(event.activeIndex);
      this.toggleFloorPlansButtonState(event.activeIndex);
      this.toggleVirtualTourButtonState(event.activeIndex);
      const formImageIndex = document.querySelector('img[data-form="true"]')! as HTMLElement;
      if (formImageIndex && event.activeIndex as number === parseInt(formImageIndex.dataset.slide as string)) {
        new AdFormService().applyImpression(this.pageData, Section.SECTION_ADPAGE_IMAGE_CAROUSEL_CONTACT, 1);
      }
    });

    mySwiper.on('slideChange', (event) => {
      this.updateExternalPhotosCounter();

      const swiperVideoButton = document.getElementsByClassName(`swiper-button-video-${this.pageData.brand.toLowerCase()}`)[0]
        ? document.getElementsByClassName(`swiper-button-video-${this.pageData.brand.toLowerCase()}`)[0]
        : document.getElementsByClassName(`swiper-button-video-${this.pageData.brand.toLowerCase()}-alpha`)[0];

      if (this.isYoutubeVideoSlideIsActive(event.activeIndex)) {
        if (this.youtubePlayer == null) {
          this.createYoutubePlayer();
        }

        swiperVideoButton.classList.add(`swiper-button-video-${this.pageData.brand.toLowerCase()}-selected`);

        // TODO - Refactor this to eliminate brand dependency
        if (this.pageData.brand !== Brand.LAMUDI && this.pageData.brand !== Brand.TROVIT) {
          if (!((this.pageData.brand === Brand.NESTORIA || this.pageData.brand === Brand.NUROA) && this.device == Device.MOBILE)) {
            (document.getElementById('videoCamSvg') as HTMLImageElement).src = 'https://imgmitulanet.s3.amazonaws.com/img/videocam-white.svg';
          }
        }

        if (this.pageData.brand === Brand.NUROA && this.device == Device.MOBILE) {
          document.getElementsByClassName('middle-wrapper__icons-gallery--button-video')[0].classList.add('selected');
        }

        if (this.youtubePlayer != null && this.youtubePlayer.getPlayerState() == YT.PlayerState.PAUSED) {
          document.getElementsByClassName('swiper-youtube-button-container')[0]!.classList.remove('swiper-youtube-button-container-hidden');
        }
      } else if (swiperVideoButton) {
        if (this.youtubePlayer != null) {
          this.youtubePlayer.pauseVideo();
        }
        swiperVideoButton.classList.remove(`swiper-button-video-${this.pageData.brand.toLowerCase()}-selected`);

        // TODO - Refactor this to eliminate brand dependency
        if (this.pageData.brand !== Brand.LAMUDI && this.pageData.brand !== Brand.PROPERATI && this.pageData.brand !== Brand.TROVIT) {
          if (!((this.pageData.brand === Brand.NESTORIA || this.pageData.brand === Brand.NUROA) && this.device == Device.MOBILE)) {
            (document.getElementById('videoCamSvg') as HTMLImageElement).src = `https://imgmitulanet.s3.amazonaws.com/img/videocam-${this.pageData.brand === Brand.MITULA && this.device == Device.MOBILE ? 'white' : this.pageData.brand.toLowerCase()}.svg`;
          }
        }

        if(this.pageData.brand === Brand.PROPERATI) {
          (document.getElementById('videoCamSvg') as HTMLImageElement).src = `https://imgmitulanet.s3.amazonaws.com/img/videocam-white.svg`;
        }

        if (this.pageData.brand === Brand.NUROA && this.device == Device.MOBILE) {
          document.getElementsByClassName('middle-wrapper__icons-gallery--button-video')[0].classList.remove('selected');
        }
      }
      const swiperYoutubeButtonContainer = document.getElementsByClassName('swiper-youtube-button-container')[0];
      if (swiperYoutubeButtonContainer) swiperYoutubeButtonContainer.classList.add('swiper-youtube-button-container-hidden');
    });

    return mySwiper;
  };

  private emmitSlideChangingEvent = (slideIndex: number) => {
    const event = new CustomEvent('swiperSlideChanged', { detail: slideIndex });
    document.dispatchEvent(event);
  };

  private identifyAndMarkFloorPlansSlides = () => {
    if (!this.floorPlansUrls) return;
    const floorPlanSlides = this.swiperSlidesImages.filter((slide) => this.floorPlansUrls!.some((url) => (slide as HTMLImageElement).src === url));
    if (floorPlanSlides && floorPlanSlides.length > 0) {
      floorPlanSlides.forEach((slide) => {
        slide.dataset.plan = 'true';
      });
    }
  };

  private isFloorPlanAvailable = () => this.floorPlanButton != null;

  private isVideoAvailable = () => this.videoButton != null;

  private isYoutubeVideoSlideIsActive = (activeSlideIndex: number) => {
    const youtubeVideo = (document.querySelectorAll('div[data-videoslide=\'true\']')[0] as HTMLElement);
    if (youtubeVideo) {
      const youtubeVideoSlideIndex = youtubeVideo.dataset.slide;
      return (parseInt(<string>youtubeVideoSlideIndex) === activeSlideIndex);
    }
    return false;
  };

  reloadNavigation() {
    this.mySwiper.navigation.update();
  }

  goToSlide(slideNumber: number, speed?: number) {
    this.mySwiper.slideTo(slideNumber, speed);
  }

  getSlideActive() {
    return this.mySwiper.activeIndex;
  }
}
