import FormValidations from './validator/FormValidations';
import PageData from '../PageData';
import UserExits from "../components/UserExits";
import {ApplyOperationType} from "../services/AdFormService";
import {Operations} from "./Operations";
import {InternationalPhoneInput} from "./validator/phone/InternationalPhoneInput";
import {Section} from "../services/Section";
import {FormValidationsHandler} from "./validator/FormValidationsHandler";

export default class ContactForm {
    private readonly pageData: PageData;
    private readonly formContainer: HTMLElement | null;
    private readonly nameElement: HTMLInputElement;
    private readonly emailElement: HTMLInputElement;
    private readonly phoneElement: HTMLInputElement;
    private readonly messageElement: HTMLTextAreaElement;
    private readonly operations: Operations;
    private readonly submitElement: HTMLElement;
    public onSubmit: (() => void) | undefined;
    private readonly section: Section | null;

    readonly isProjectForm: null | boolean;
    private readonly formValidationsHandler: FormValidationsHandler;

    constructor(params: {
        pageData: PageData,
        formContainer: HTMLElement | null,
        nameElement: HTMLInputElement,
        emailElement: HTMLInputElement,
        phoneElement: HTMLInputElement,
        messageElement: HTMLTextAreaElement,
        submitElement: HTMLElement,
        onSubmit?: () => void,
        internationalPhoneInput?: InternationalPhoneInput
    }) {
        this.pageData = params.pageData;
        this.formContainer = params.formContainer;
        this.nameElement = params.nameElement;
        this.emailElement = params.emailElement;
        this.phoneElement = params.phoneElement;
        this.messageElement = params.messageElement;
        this.submitElement = params.submitElement;
        this.onSubmit = params.onSubmit;
        this.operations = new Operations(this.formContainer)
        this.section = (this.formContainer && this.formContainer.getAttribute('data-form-section')) as Section | null;
        this.isProjectForm = this.formContainer && this.formContainer.getAttribute('data-is-project-form') != null && this.formContainer.getAttribute('data-is-project-form') === "true";
        this.formValidationsHandler = new FormValidationsHandler(
            this.pageData,
            this.operations,
            this.nameElement,
            this.emailElement,
            this.phoneElement,
            params.internationalPhoneInput
        )
        this.setupEventListeners();
    }

    private setupEventListeners() {
        if (this.phoneElement) this.phoneElement.addEventListener('keyup', this.formValidationsHandler.checkPhoneIsValid);
        if (this.submitElement) this.submitElement.addEventListener('click', this.submit);
    }

    private submit = () => {
        const areValidInputs = this.formValidationsHandler.validateInputs();
        if (!areValidInputs) return;

        if (this.pageData.brand === 'MITULA'
            && (this.pageData.country === 'PE' || this.pageData.country === 'TH' || this.pageData.country === 'CO' || this.pageData.country === 'EC' || this.pageData.country === 'CL')) {
            // @ts-ignore
            gtag_report_conversion();
        }

        this.formContainer!.classList.add('submitted');
        if (this.onSubmit) {
            UserExits.sendMultiEnquiryImpressions(
                this.pageData,
                this.userExitsElement()
            );

            UserExits.sendPostEnquirySimilarsImpressions(
                this.pageData,
                this.userExitsElement()
            );

            this.theLeadHasBeenSent();

            this.onSubmit();
        }
    };

    selectedOperationType(): ApplyOperationType | null {
        return this.operations.selectedOperation()
    }

    public userExitsElement(): HTMLElement {
        return this.formContainer!.querySelector('[data-tracking-section]') as HTMLElement;
    }

    private validatedMessage = (): string => FormValidations.buildFormMessage(this.messageElement);

    private theLeadHasBeenSent = () => {
        const metaTag = document.querySelector('meta[name="aLeadHasBeenSent"]') as HTMLMetaElement | null;
        if (metaTag) {
            metaTag.content = 'true';
        }
    };

    nameValue = () => this.nameElement.value;

    emailValue = () => this.emailElement.value;

    phoneValue = () => this.phoneElement.value;

    messageValue = () => this.validatedMessage();

    getSection = () => this.section;

    existsForm = () => this.formContainer != null;

    rentStartDate = () => {
        return this.operations.rentStartDate()
    }
    rentEndDate = () => {
        return this.operations.rentEndDate()
    }

    scheduleDate = (): string | null => {
        const dateInput = this.formContainer?.querySelector(".js-date-value:checked");
        if (!dateInput) return null;
        return (dateInput as HTMLInputElement).value;
    }

    scheduleTime = (): string | null => {
        const timeInput = this.formContainer?.querySelector(".js-time-value:checked");
        if (!timeInput) return null;
        return (timeInput as HTMLInputElement).value;
    }
}
